@import 'fonts';

html, body {
    padding: 0px;
    margin: 0px;
    // font-family: 'UIDSALMON2019';
    color:#ffffff;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
    font-size:20px;
    @media(max-width:1024px){
        font-size:10px;
    }
     @media(min-width: 1600px){
        font-size: 26px;
    }
}
* {
    box-sizing: border-box;
}

a{
	&:visited, &:hover, &:active, &:focus{
        cursor: pointer;
        text-decoration: none;
	}
}

img{
    max-width: 100%;
    max-height: 100%;
}
