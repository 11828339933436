@mixin font-face($style-name, $file, $category: '') {
  $filepath: '../static/fonts/' + $file;
  @font-face {
    font-family: '#{$style-name}';
    src: url($filepath+'.eot');
    src: url($filepath+'.eot?#iefix') format('embedded-opentype'),
      url($filepath+'.woff') format('woff'),
      url($filepath+'.ttf') format('truetype'),
      url($filepath+'.svg#'+$style-name+'') format('svg');
  }
  %#{$style-name} {
    font: {
      @if $category != '' {
        family: '#{$style-name}', #{$category};
      } @else {
        family: '#{$style-name}';
        weight: normal;
      }
    }
  }
}

@include font-face('Chonburi-Regular', 'Chonburi-Regular', 'serif');
@include font-face('DBHeavent-BlackIt', 'DBHeavent-BlackIt', 'tahoma');
@include font-face('DBHeavent-Bold', 'DBHeavent-Bold', 'tahoma');
@include font-face('DBHeavent-Med', 'DBHeavent-Med', 'tahoma');
@include font-face('DBHeavent', 'DBHeavent', 'tahoma');
@include font-face('Prompt-Regular', 'Prompt-Regular', 'tahoma');
